rodijco.controller 'CertificatesGroupFormController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, location, httpService) ->

    scope.formData = {}

    scope.fields_count = [0..5];
    scope.fields_middle_count = [0..1];
    scope.fields_bottom_count = [0..3];

    scope.typeOptions =
      [{
        id: '0',
        name: 'Text veld'
      },
      {
        id: '1',
        name: 'Text area'
      },
      {
        id: '3',
        name: 'Klant (met autocomplete)'
      },
      {
        id: '4',
        name: 'Datum'
      },
      {
        id: '5',
        name: 'Handtekening'
      },
      {
        id: '6',
        name: 'Certificaat nummer'
      }]

    scope.edit = false
    scope.edit = true if routeParams.id != 'new'

    if scope.edit
      httpService.get "certificates/groups/#{routeParams.id}", (response) ->
        scope.formData = response.data

    httpService.get 'config', (response) ->
      scope.config = response.data[0]
      # scope.formData.header.image = 0
      # console.log scope.formData.header.image

    scope.save = (formData, forceNew = false) ->
      formData._id = routeParams.id

      if forceNew
        formData._id = 'new'
        routeParams.id = 'new'

      httpService.post "certificates/groups/#{routeParams.id}", formData, (response) ->
        if response.success
          scope.formData = {}
          Materialize.toast 'Certificaat groep opgeslagen', DIALOG_DURATION
          if routeParams.id == 'new'
            location.path('/certificates')
          else
            location.path('/certificates/' + routeParams.id)
        else
          scope.message = response.data?.message

]
